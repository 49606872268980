// project imports
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// ==============================|| MAIN ROUTING ||============================== //

const Dashboard = Loadable(lazy(() => import('views/dashboard')));

const UserList = Loadable(lazy(() => import('views/users/UserList')));
const UserAdd = Loadable(lazy(() => import('views/users/UserAdd')));
const UserEdit = Loadable(lazy(() => import('views/users/UserEdit')));
const UserView = Loadable(lazy(() => import('views/users/UserView')));
const UserProfile = Loadable(lazy(() => import('views/users/profile')));

const AdminDevelopmentProjectsList = Loadable(lazy(() => import('views/devProjects/list')));
const AdminDevelopmentProjectsEdit = Loadable(lazy(() => import('views/devProjects/edit')));
const AdminDmrvProjectsList = Loadable(lazy(() => import('views/dmrvProjects/list')));
const AdminDmrvProjectsEdit = Loadable(lazy(() => import('views/dmrvProjects/edit')));
const AdminMarketplaceList = Loadable(lazy(() => import('views/marketplace/list')));
const AdminMarketplaceEdit = Loadable(lazy(() => import('views/marketplace/edit')));
const AdminInterestForms = Loadable(lazy(() => import('views/interestForms/list')));
const AdminInterestFormsDetails = Loadable(lazy(() => import('views/interestForms/details')));
const AdminFPICList = Loadable(lazy(() => import('views/fpic-reports/list')));
const AdminEditFPICPage = Loadable(lazy(() => import('views/fpic-reports/edit')));

// FINANCE APPLICATIONS
const FinanceApplications = Loadable(lazy(() => import('views/applications/list')));
const EditFinanceApplication = Loadable(lazy(() => import('views/applications/edit')));

// Technical Support
const TechnicalSupportList = Loadable(lazy(() => import('views/TSRequests/list')));

// Investor Feedbacks
const InvestorFeedbacks = Loadable(lazy(() => import('views/InvestorFeedbacks/list')));

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'users',
            children: [
                {
                    path: 'list',
                    element: <UserList />
                },
                {
                    path: 'view/:id',
                    element: <UserView />
                },
                {
                    path: 'add',
                    element: <UserAdd />
                },
                {
                    path: 'edit/:id',
                    element: <UserEdit />
                },
                {
                    path: 'self',
                    element: <UserProfile />
                }
            ]
        },
        {
            path: 'projects',
            children: [
                {
                    path: 'dev-projects',
                    element: <AdminDevelopmentProjectsList />
                },
                {
                    path: 'dev-projects/edit/:id',
                    element: <AdminDevelopmentProjectsEdit />
                },
                {
                    path: 'dMRV-projects',
                    element: <AdminDmrvProjectsList />
                },
                {
                    path: 'dmrv-projects/edit/:id',
                    element: <AdminDmrvProjectsEdit />
                }
            ]
        },
        {
            path: 'applications',
            children: [
                {
                    path: 'list',
                    element: <FinanceApplications />
                },
                {
                    path: 'manage/:id',
                    element: <EditFinanceApplication />
                }
            ]
        },
        {
            path: 'technical-support-requests',
            children: [
                {
                    index: true,
                    element: <TechnicalSupportList />
                }
            ]
        },
        {
            path: 'investor-feedbacks',
            children: [
                {
                    index: true,
                    element: <InvestorFeedbacks />
                }
            ]
        },
        {
            path: 'marketplace',
            children: [
                {
                    path: 'listings',
                    element: <AdminMarketplaceList />
                },
                {
                    path: 'listings/:listingId/instances/:instanceId',
                    element: <AdminMarketplaceEdit />
                }
            ]
        },
        {
            path: 'fpic-reports',
            children: [
                {
                    path: 'list',
                    element: <AdminFPICList />
                },
                {
                    path: 'edit/:id',
                    element: <AdminEditFPICPage />
                }
            ]
        },
        {
            path: 'interest-forms',
            children: [
                {
                    path: 'list',
                    element: <AdminInterestForms />
                },
                {
                    path: 'view/:id?',
                    element: <AdminInterestFormsDetails />
                }
            ]
        }
    ]
};

export default MainRoutes;
