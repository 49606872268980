import { RouterProvider } from 'react-router-dom';

// routing
import router from 'routes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Locales from 'ui-component/Locales';
// import RTLLayout from 'ui-component/RTLLayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Snackbar from 'ui-component/extended/Snackbar';
import Notistack from 'ui-component/third-party/Notistack';

import ThemeCustomization from 'themes';

// auth provider
import { AuthProvider } from 'contexts/Auth0Context';
import { SWRConfig } from 'swr';
// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* <RTLLayout> */}
        <Locales>
            <NavigationScroll>
                <SWRConfig
                    value={{
                        revalidateOnFocus: false,
                        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                            // Never retry on 404.
                            if (error.status === 404) return;

                            // Only retry up to 10 times.
                            if (retryCount >= 3) return;

                            // Retry after 5 seconds.
                            setTimeout(() => revalidate({ retryCount }), 5000);
                        }
                    }}
                >
                    <AuthProvider>
                        <>
                            <Notistack>
                                <RouterProvider router={router} />
                                <Snackbar />
                                <ToastContainer position="top-center" autoClose={3000} />
                            </Notistack>
                        </>
                    </AuthProvider>
                </SWRConfig>
            </NavigationScroll>
        </Locales>
        {/* </RTLLayout> */}
    </ThemeCustomization>
);

export default App;
