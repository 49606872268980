import { useContext } from 'react';

// auth provider

import { Auth0Context } from 'contexts/Auth0Context';

// ==============================|| AUTH HOOKS ||==============================

const useAuth = () => {
    const context = useContext(Auth0Context);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useAuth;
