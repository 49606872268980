/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:3010/' });

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export const fetcher = async (args) => {
    const [url, config] = Array.isArray(args) ? args : [args];

    const res = await axiosServices.get(url, { ...config });

    return res.data;
};

export const convertToFormData = (data) => {
    const formData = new FormData();
    for (const field in data) {
        if (Array.isArray(data[field])) {
            for (let i = 0; i < data[field].length; i += 1) {
                formData.append(field, data[field][i]);
            }
        } else {
            formData.append(field, data[field]);
        }
    }
    return formData;
};

export const endpoints = {
    application: {
        create: `/projects-service/finance-applications`,
        update: (applicationId) => `/projects-service/finance-applications/${applicationId}`,
        delete: (applicationId) => `/projects-service/finance-applications/${applicationId}`,
        details: (applicationId) => `/projects-service/finance-applications/${applicationId}`,
        list: `/projects-service/finance-applications`
    }
};

export default axiosServices;
